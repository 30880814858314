import * as Sentry from '@sentry/react';
import { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import ImpersonationBanner from './components/admin/impersonate/impersonationBanner';
import QueryWrapper from './queryWrapper';
import { store } from './redux/store';
import { Routing } from './routes';
import './styles/index.css';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// if (import.meta.env.MODE === 'production') {
Sentry.init({
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `hw-management-portal-app@${process.env.npm_package_version}`,
  tracePropagationTargets: [
    'http://localhost:33100',
    'https://hw-d-app-m4s-frontend.azurewebsites.net/',
  ],
  ignoreErrors: [
    /^ResizeObserver loop completed with undelivered notifications.$/,
  ],
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
});
// }

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <Provider store={store}>
    <QueryWrapper>
      <Suspense fallback={<></>}>
        <Routing />
      </Suspense>
      <ImpersonationBanner />
    </QueryWrapper>
  </Provider>,
);
