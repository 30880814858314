import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InstitutionAccountRoleResponse } from '@signpost-hardware/management-portal-models/v1/app/accounts/responses/institution_account_role_response_pb';
import { InstitutionAccountRequest } from '@signpost-hardware/management-portal-models/v1/institution_accounts/requests/institution_account_request_pb';
import { InstitutionAccountResponse } from '@signpost-hardware/management-portal-models/v1/institution_accounts/responses/institution_account_response_pb';
import { InstitutionRoleResponse } from '@signpost-hardware/management-portal-models/v1/institution_roles/responses/institution_role_response_pb';
import { InstitutionResponse } from '@signpost-hardware/management-portal-models/v1/institutions/responses/institution_response_pb';
import { Table } from '@signpost-hardware/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { fetchAccount } from '../../../helpers/axios/configs/accountAxios';
import { fetchAccounts } from '../../../helpers/axios/configs/accountsAxios';
import { fetchAppInstitutions } from '../../../helpers/axios/configs/appInstitutionsAxios';
import { fetchInstitutionsRoles } from '../../../helpers/axios/configs/institutionRolesAxios';
import { PaginatedCollection } from '../../../types/apiResponse';
import { EFilterType } from '../../../types/enum/filterType';
import Tooltip from '../../common/Tooltip';

type TUserResponse = {
  subject: string;
  firstName?: string;
  lastName?: string;
  email: string;
};

const GiveAccessButton = ({
  institutionGuid,
  currentUser,
  userRoles,
}: {
  institutionGuid: string;
  currentUser: TUserResponse;
  userRoles: InstitutionAccountRoleResponse[];
}) => {
  const institutionRoleGuid = useQuery<string>({
    queryKey: ['institutions', 'platformUsers', 'roles'],
    queryFn: async () => {
      const res = await fetchInstitutionsRoles.get<
        PaginatedCollection<InstitutionRoleResponse>
      >('', {
        params: {
          $filter: `advanced ${EFilterType.Equals} null`,
        },
      });
      return (
        res.data.items.filter((role) => role.name === 'Read User')[0].guid || ''
      );
    },
  });

  const permissionAddMutation = useMutation<InstitutionAccountResponse, Error>({
    mutationFn: async () => {
      const res = await fetchAppInstitutions.post<
        InstitutionAccountRequest,
        AxiosResponse<InstitutionAccountResponse>
      >(`${institutionGuid}/accounts`, {
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        email: currentUser.email,
        institutionGuid,
        institutionRoleGuid: institutionRoleGuid.data,
      });
      return res.data;
    },

    onSuccess: () => {
      window.location.reload();
    },
  });

  const permissionRemoveMutation = useMutation<
    InstitutionAccountResponse,
    Error,
    InstitutionAccountRoleResponse
  >({
    mutationFn: async (institutionRole) => {
      const res = await fetchAppInstitutions.delete<
        InstitutionAccountRequest,
        AxiosResponse<InstitutionAccountResponse>
      >(
        `${institutionRole.institutionGuid}/accounts/${institutionRole.institutionAccountGuid}/roles/${institutionRole.guid}`,
      );
      return res.data;
    },

    onSuccess: () => {
      window.location.reload();
    },
  });

  if (institutionRoleGuid.isLoading) return <></>;
  if (institutionRoleGuid.error || !institutionRoleGuid.data) return <></>;

  if (userRoles.map((item) => item.institutionGuid).includes(institutionGuid)) {
    return (
      <button
        onClick={() =>
          permissionRemoveMutation.mutate(
            userRoles.filter(
              (item) => item.institutionGuid === institutionGuid,
            )[0],
          )
        }>
        Remove rights
      </button>
    );
  }

  return (
    <div className='flex gap-2'>
      <button
        onClick={() => permissionAddMutation.mutate()}
        className='bg-app-100 hover:bg-app-300 border border-app-400 rounded-md px-4 py-2 whitespace-nowrap'>
        Get access
      </button>
    </div>
  );
};

const createColumns = (
  currentUser: TUserResponse,
  userRoles: InstitutionAccountRoleResponse[],
): ColumnDef<InstitutionResponse, unknown>[] => {
  return [
    {
      id: 'guid',
      header: 'Guid',
      cell: ({ row }) => {
        return (
          <Tooltip message={row.original.guid || ''}>
            <button
              className='flex items-center gap-2 hover:text-app-500 transition-colors'
              onClick={() => {
                navigator.clipboard.writeText(row.original.guid ?? '');
                toast.info('Copied');
              }}>
              <span className='whitespace-nowrap text-ellipsis overflow-hidden max-w-40'>
                {row.original.guid}
              </span>
              <FontAwesomeIcon icon={faCopy} size={'sm'} />
            </button>
          </Tooltip>
        );
      },
      enableSorting: false,
    },
    {
      id: 'institution',
      accessorKey: 'name',
      header: 'Institution',
      enableSorting: false,
    },
    {
      id: 'options',
      cell: ({ row }) => {
        return (
          <GiveAccessButton
            institutionGuid={row.original.guid || ''}
            currentUser={currentUser}
            userRoles={userRoles}
          />
        );
      },
      enableHiding: false,
      enableSorting: false,
    },
  ];
};

const InstitutionAccessTable = () => {
  const currentUser = useQuery<TUserResponse>({
    queryKey: ['userData'],
    queryFn: async () => {
      const res = await fetchAccount.get('/userinfo');
      return res.data;
    },
  });

  const userRoles = useQuery<InstitutionAccountRoleResponse[]>({
    queryKey: ['userData', 'roles', currentUser.data?.subject],
    queryFn: async () => {
      const res = await fetchAccounts.get('institution-account-roles', {
        params: {
          $filter: `AccountGuid ${EFilterType.Equals} ${currentUser.data?.subject}`,
        },
      });
      return res.data.items;
    },
    enabled: !!currentUser.data?.subject,
  });

  if (currentUser.isLoading || userRoles.isLoading) return <></>;
  if (
    currentUser.error ||
    userRoles.error ||
    !currentUser.data ||
    !userRoles.data
  )
    return <></>;

  const columns = createColumns(currentUser.data, userRoles.data);
  return (
    <Table<InstitutionResponse>
      columns={columns}
      fetchOptions={{
        axiosInstance: fetchAppInstitutions,
      }}
      tableKey='institutionAccess'
      searchable
    />
  );
};

export default InstitutionAccessTable;
