import { useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RootState } from '../redux/store';
import { createRoutes } from './createRoutes';
import * as Sentry from '@sentry/react';

export const Routing = () => {
  const routing = useSelector((state: RootState) => state.routing);
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
  const router = sentryCreateBrowserRouter(createRoutes(routing), {});

  return <RouterProvider router={router} />;
};
