import { Link, useLocation } from 'react-router-dom';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getFirstLetter } from '../../helpers/stringHelpers';
import { useEffect } from 'react';
import LanguageSelector from './LanguageSelector.tsx';
import { useContent } from '../../hooks/useContent.ts';
import { contentNameMapping } from '../../helpers/componentMappings/contentNameMapping.ts';
import * as Sentry from '@sentry/react';
import { Authentication } from '@/helpers/auth.ts';
import { useDispatch } from 'react-redux';
import { setLanguage } from '@/redux/slices/languageSlice.ts';
import { getCookie } from '@signpost-hardware/utils';

const Profile = () => {
  const dispatch = useDispatch();
  const translationSchemaName = 'profile-content';
  const { data: translation } = useContent(
    translationSchemaName,
    contentNameMapping[translationSchemaName],
  );
  const location = useLocation();
  const [showProfile, setShowProfile, profileRef] =
    useClickOutside<HTMLDivElement>({
      ignoreClass: 'ignoreProfile',
    });

  useEffect(() => {
    setShowProfile(false);
  }, [location, setShowProfile]);

  const userData = useQuery<{
    subject: string;
    firstName?: string;
    lastName?: string;
    email: string;
    hasProfilePhoto: boolean;
  }>({
    queryKey: ['userData'],
    queryFn: async () => {
      const token = Authentication.authToken;
      const res = await axios.get(
        `${import.meta.env.VITE_AUTH_AUTHORITY}/oauth2/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (import.meta.env.MODE === 'production') {
        Sentry.setUser({ email: res.data.email, guid: res.data.subject });
      }
      if (res.data.locale && !getCookie('lng'))
        dispatch(setLanguage(res.data.locale));
      return res.data;
    },
  });
  const profilePicture = useQuery({
    queryKey: ['profilePicture'],
    queryFn: async () => {
      const token = Authentication.authToken;
      const res = await axios.get(
        `${import.meta.env.VITE_AUTH_AUTHORITY}/account/me/photo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        },
      );
      return URL.createObjectURL(res.data);
    },
    enabled: !!userData.data?.hasProfilePhoto,
  });

  //   TODO: Loading and error state
  if (userData.isLoading)
    return (
      <div className='rounded-full w-11 h-11 bg-alpha-500 animate-pulse'></div>
    );
  if (userData.error || !userData.data)
    return <div>Failed to load profile</div>;

  let initials = `${getFirstLetter(userData.data.firstName || '')}${getFirstLetter(
    userData.data.lastName || '',
  )}`;
  if (initials === '') initials = 'UU';

  return (
    <>
      <button
        className='ignoreProfile w-11 h-11'
        onClick={() => setShowProfile(!showProfile)}>
        {profilePicture.data &&
        (!profilePicture.error || !profilePicture.isLoading) ? (
          <img
            src={profilePicture.data}
            alt={initials}
            className='rounded-full w-11 h-11 object-cover'
          />
        ) : (
          <span className='bg-signpost rounded-full w-11 h-11 whitespace-nowrap text-alpha font-bold text-lg uppercase flex items-center justify-center'>
            {initials}
          </span>
        )}
      </button>
      <div
        ref={profileRef}
        className={`${
          showProfile ? 'flex' : 'hidden'
        } headerCard absolute top-full m-0 right-0 z-20 bg-alpha p-8 flex-col items-center gap-6 shadow-lg shadow-app-800/20 rounded-xl`}>
        <div className='flex flex-col items-center gap-2 w-full'>
          {profilePicture.data &&
          (!profilePicture.error || !profilePicture.isLoading) ? (
            <img
              src={profilePicture.data}
              alt={initials}
              className='rounded-full w-11 h-11 object-cover'
            />
          ) : (
            <span className='bg-signpost rounded-full w-11 h-11 whitespace-nowrap text-alpha font-bold text-lg uppercase flex items-center justify-center'>
              {initials}
            </span>
          )}

          <span
            className='overflow-hidden text-ellipsis whitespace-nowrap w-full text-center'
            title={userData.data.email}>
            {userData.data.email}
          </span>
        </div>
        {/* <Link to='/profile'>
          <Button variant='outline-dark' className='px-6'>
            Profiel
          </Button>
        </Link> */}
        <div className='flex items-center justify-between mt-4 w-full'>
          <LanguageSelector />
          <Link to='/signout' className='flex items-center gap-2'>
            <span>{translation?.signOut}</span>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className='text-app-800'
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Profile;
