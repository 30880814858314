import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountResponse } from '@signpost-hardware/management-portal-models/v1/app/accounts/responses/account_response_pb';
import { Table } from '@signpost-hardware/react-table';
import { useMutation } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchImpersonation } from '../../../helpers/axios/configs/impersonationAxios';
import { setInstitution } from '../../../redux/slices/institutionSlice';
import Tooltip from '../../common/Tooltip';

const ImpersonateButton = ({
  account,
  email,
}: {
  account: string;
  email: string;
}) => {
  const dispatch = useDispatch();
  const impersonateMutation = useMutation({
    mutationFn: async () => {
      const res = await fetchImpersonation.put('', {
        email,
        guid: account,
      });
      return res.data;
    },
    onSuccess: () => {
      dispatch(setInstitution(null));
      sessionStorage.setItem('impersonate', JSON.stringify({ email, account }));
      window.location.href = '/';
    },
  });

  return (
    <button
      onClick={() => impersonateMutation.mutate()}
      className='bg-app-100 hover:bg-app-300 border border-app-400 rounded-md px-4 py-2'>
      Impersonate
    </button>
  );
};

const createColumns = (): ColumnDef<AccountResponse, unknown>[] => {
  return [
    {
      id: 'guid',
      header: 'Guid',
      cell: ({ row }) => {
        return (
          <Tooltip message={row.original.guid}>
            <button
              className='flex items-center gap-2 hover:text-app-500 transition-colors'
              onClick={() => {
                navigator.clipboard.writeText(row.original.guid ?? '');
                toast.info('Copied');
              }}>
              <span className='whitespace-nowrap text-ellipsis overflow-hidden max-w-40'>
                {row.original.guid}
              </span>
              <FontAwesomeIcon icon={faCopy} size={'sm'} />
            </button>
          </Tooltip>
        );
      },
      enableSorting: false,
    },
    {
      id: 'firstname',
      accessorKey: 'firstName',
      header: 'FirstName',
      enableSorting: false,
    },
    {
      id: 'lastname',
      accessorKey: 'lastName',
      header: 'LastName',
      enableSorting: false,
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: 'Email',
      enableSorting: false,
    },
    {
      id: 'options',
      cell: ({ cell }) => {
        return (
          <ImpersonateButton
            account={cell.row.original.guid}
            email={cell.row.original.email}
          />
        );
      },
      enableHiding: false,
      enableSorting: false,
    },
  ];
};

const ImpersonationTable = () => {
  const columns = createColumns();

  return (
    <Table<AccountResponse>
      columns={columns}
      fetchOptions={{
        axiosInstance: fetchImpersonation,
        subUrl: '/accounts',
      }}
      tableKey='impersonation'
      searchable
    />
  );
};

export default ImpersonationTable;
